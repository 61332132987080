import $ from 'jquery';
import { createFocusTrap } from 'focus-trap';
const TABBABLE_ELEMENT_SELECTOR = 'a, button, textarea, input, select, .js__custom-dropdown-button';
export default class TrapFocus {
    static removeMainContentTabIndex($focusTrapElement, focusTrapId) {
        this.removeTabIndexInElement($('header, .js__main-content, footer'), $focusTrapElement, focusTrapId);
    }
    static addMainContentTabIndex(focusTrapId) {
        this.addTabIndexInElement($('header, .js__main-content, footer'), focusTrapId);
    }
    static removeTabIndexInElement($element, $focusTrapElement = null, focusId) {
        const $tabbableElements = $element.find(TABBABLE_ELEMENT_SELECTOR);
        $tabbableElements.each((index, element) => {
            const $tabbableElement = $(element);
            if ($focusTrapElement && $tabbableElement.closest($focusTrapElement).length) {
                return;
            }
            if ($tabbableElement.attr('tabindex') !== '-1') {
                $tabbableElement.addClass('js__removed-tab-index');
                if (focusId) {
                    $tabbableElement.attr('focus-trap-id', focusId);
                }
                if ($tabbableElement.attr('tabindex') === '0') {
                    $tabbableElement.addClass('js__removed-tab-index-0');
                }
                else {
                    $tabbableElement.addClass('js__removed-tab-index');
                }
                $tabbableElement.attr('tabindex', -1);
                $tabbableElement.attr('aria-hidden', 'true');
            }
        });
    }
    static addTabIndexInElement($element, focusId) {
        const $tabbableElements = $element.find(TABBABLE_ELEMENT_SELECTOR);
        $tabbableElements.each((index, element) => {
            const $tabbableElement = $(element);
            if ($tabbableElement.hasClass('js__removed-tab-index')) {
                if (focusId && $tabbableElement.attr('focus-trap-id') !== focusId) {
                    return;
                }
                if (focusId && $tabbableElement.attr('focus-trap-id') === focusId) {
                    $tabbableElement.removeAttr('focus-trap-id');
                }
                if ($tabbableElement.hasClass('js__removed-tab-index-0')) {
                    $tabbableElement.attr('tabindex', '0');
                }
                else {
                    $tabbableElement.removeAttr('tabindex');
                }
                $tabbableElement.attr('aria-hidden', 'false');
                $tabbableElement.removeClass('js__removed-tab-index');
            }
        });
    }
    static createTrapFocus(element, focusElement, focusTrapId) {
        return createFocusTrap(element, {
            initialFocus: focusElement,
            onActivate: () => {
                TrapFocus.removeMainContentTabIndex($(element), focusTrapId);
            },
            onDeactivate: () => {
                TrapFocus.addMainContentTabIndex(focusTrapId);
            },
        });
    }
}
